<template>
  <div>
    <v-form @submit.prevent="buscar">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <z-input v-model="query.mes" tipo="mes" label="Mes de pago" />
            </v-col>
            <v-col>
              <v-spacer />
              <z-btn :loading="loading" color="primary" outlined type="submit"> Actualizar </z-btn>
              <z-btn
                :loading="loading"
                class="ml-2"
                color="primary"
                outlined
                @click="dialogVisible = true"
              >
                Subir tabla
              </z-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <v-card v-if="reajustes.length > 0">
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <th />
              <th v-for="mes in 12" :key="`titulo-mes-${mes}`">
                {{ mes }}
              </th>
            </tr>
            <template v-for="(reajustesAno, ano) in reajustes">
              <tr v-if="reajustesAno" :key="`ano-${ano}`">
                <th>{{ ano }}</th>
                <td
                  v-for="mes in 12"
                  :key="`mes-${ano}-${mes}`"
                  class="text-right"
                  :title="reajustesAno[mes]"
                >
                  {{ redondear(reajustesAno[mes]) }}
                </td>
              </tr>
            </template>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialogVisible" persistent width="500">
      <v-form @submit.prevent="subir">
        <v-card>
          <dialog-toolbar @close="dialogVisible = false"> Subir tabla de reajustes </dialog-toolbar>
          <v-card-text>
            <p>
              El archivo debe estar en formato Excel y lo puedes descargar desde la página
              <a href="https://www.tgr.cl/tablas-de-calculo/" target="_blank">Tablas de cálculo</a>
              de la TGR.
            </p>
            <v-row>
              <v-col cols="7">
                <z-input v-model="form.mes" tipo="mes" label="Mes de pago" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-file-input
                  v-model="form.archivo"
                  accept=".xls"
                  show-size
                  counter
                  prepend-icon="mdi-paperclip"
                  outlined
                  label="Archivo Excel"
                  hint="Tamaño máximo: 1 MB"
                  persistent-hint
                  :error-messages="errors.archivo"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="secondary" text class="mb-2" @click="dialogVisible = false">
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              :disabled="!form.mes || !form.archivo || form.archivo.size > 1048576"
              type="submit"
            >
              Subir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import ZInput from '@/components/Input/ZInput'
import DialogToolbar from '@/components/DialogToolbar'

export default {
  components: { DialogToolbar, ZInput, ZBtn },
  data() {
    return {
      dialogVisible: false,
      reajustes: [],
      errors: {},
      loading: false,
      query: {
        mes: '',
      },
      form: {
        mes: '',
        archivo: null,
      },
    }
  },
  computed: {},
  beforeMount() {
    this.query.mes = this.$dayjs().format('YYYY-MM')
    this.form.mes = this.$dayjs().format('YYYY-MM')
  },
  methods: {
    buscar() {
      this.loading = true
      this.reajustes = []
      this.anos = []
      const pago = this.query.mes.replace('-', '')
      // TODO: Convertir a POST a /search
      this.$axios.post('pagos/reajustes/search', { filter: { pago: pago } }).then((response) => {
        const reajustes = []
        let ano = 0
        let mes = 0
        response.data.data.forEach((reajuste) => {
          ano = parseInt(reajuste.vencimiento.toString().substr(0, 4))
          mes = parseInt(reajuste.vencimiento.toString().substr(4, 2))
          reajustes[ano] ??= []
          reajustes[ano][mes] = reajuste.factor
        })
        this.reajustes = reajustes
        this.loading = false
      })
    },
    subir() {
      if (!this.form.archivo || this.form.archivo.name.split('.').pop() !== 'xls') {
        alert('Por favor, seleccione un archivo con formato XLS.')
        return
      }

      this.loading = true
      const formData = new FormData()
      formData.append('archivo', this.form.archivo)
      formData.append('mes', this.form.mes)
      const headers = { 'Content-Type': 'multipart/form-data' }
      this.$axios.post('pagos/reajustes/cargar', formData, { headers }).then((response) => {
        this.dialogVisible = false
        this.loading = false
        // BUsca los datos recien cargados
        this.query.mes = this.form.mes
        this.buscar()
        alert('La tabla de reajustes ha sido cargada')
      })
    },
    redondear(value) {
      if (isNaN(value)) {
        return ''
      }
      return Math.round(value * 10) / 10
    },
  },
}
</script>
